import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/reg')
  },
  {
    path: '/regSuccess',
    name: 'regSuccess',
    component: () => import('../views/regSuccess')
  },
  {
    path: '/ability',
    name: 'ability',
    component: () => import('../views/ability')
  },
  {
    path: '/priceIntroduce',
    name: 'priceIntroduce',
    component: () => import('../views/priceIntroduce')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/personal')
  },
  {
    path: '/internalControl',
    name: 'internalControl',
    component: () => import('../views/internalControl')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('../views/resetPassword')
  }
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
